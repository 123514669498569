//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2011, 2012 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

// NLS_CHARSET=UTF-8
storeNLS = ({
	HISTORY:"History",
	//AutoSuggestion Headings
	ARTICLES: "Articles",
	BRAND: "Brand",
	CATEGORY: "Category",
	//Product Description
	ERR_RESOLVING_SKU : "Your selection is either incomplete or not in stock. Please be sure to supply a value for each attribute or consider a different combination of values.",
	QUANTITY_INPUT_ERROR : "The value in the Quantity field is invalid. Ensure the value is a positive integer and try again.",
	BOPIS_AVAILABILITY_ERROR : "This item is not currently available for pick up in store you have selected. Please check store availability for pick up at another store.",
	//Power plus warranty Cart page
	QUANTITY_WARRANTY_INPUT_ERROR : "Plan quantity may not exceed product quantity",
	WISHLIST_ADDED : "The item has been successfully added to your Wish List",
	//ECOM:36226 
	TSC_MAX_QTY : "10000",
	TSC_INPUT_QTY_EXCEEDED : "The maximum quantity for \"${0}\" in the shopping cart is ${2} and you have specified ${1}. Specify a lower quantity",
	
	SHOPCART_ADDED : "The item has been successfully added to your shopping cart.",
	PRICE : "Price:",
	SKU : "SKU:",
	PQ_PURCHASE : "Purchase:",
	PQ_PRICE_X : "${0} -",
	PQ_PRICE_X_TO_Y : "${0} to ${1} -",
	PQ_PRICE_X_OR_MORE : "${0} or more -",
	
	COMPARE_ITEM_EXISTS : "The product you are trying to add to the compare zone already exists.",
	COMPATE_MAX_ITEMS : "You can only compare up to 4 products.",
	COMPAREZONE_ADDED : "The Item has been successfully added to the compare zone.",
	
	GENERICERR_MAINTEXT : "The store has encountered a problem processing the last request. Please try again. If the problem persists, ${0} for assistance.",
	GENERICERR_CONTACT_US : "contact us",
	
	MSC_ITEMS : "${0} item(s)",
	
	// Shopping List Messages
	DEFAULT_WISH_LIST_NAME : "Wish List",
	LIST_CREATED : "Wish list successfully created.",
	LIST_EDITED : "Wish list name successfully changed.",
	LIST_DELETED : "Wish list successfully deleted.",
	ITEM_ADDED : "The item has been added to your wish list.",
	ITEM_REMOVED : "The item has been removed from your wish list.",
	ERR_NAME_EMPTY : "Type a name for your wish list.",
	ERR_NAME_TOOLONG : "Wish list name is too long.",
	ERR_NAME_SHOPPING_LIST : "Wish List is a reserved name for the default wish list. Choose a different name.",
	ERR_NAME_DUPLICATE : "A wish list already exists with the name you have chosen. Choose a different name.",
	INVALID_NAME_SHOPPING_LIST : "The wish list name contains invalid characters.  Provide an alphanumeric name.",
	WISHLIST_EMAIL_SENT : "Your e-mail has been sent.",
	WISHLIST_MISSINGNAME : "The Name field cannot be blank. Type your name in the Name field and try again.",
	WISHLIST_MISSINGEMAIL : "The E-mail address field cannot be blank. Type the e-mail address of the person to whom you are sending your wish list and try again.",
	WISHLIST_INVALIDEMAILFORMAT : "Invalid E-mail address format.",
	WISHLIST_EMPTY : "Please create a wish list before sending an email.",
	WISHLIST_INVALIDSENDERNAME : "The Name field contains invalid characters. Type your name in the Name field and try again.",
		
	// Inventory Status Messages
	INV_STATUS_RETRIEVAL_ERROR : "An error occurred while retrieving inventory status. Try again later. If the problem persists, contact your site administrator.",
	INV_ATTR_UNAVAILABLE: "${0} - unavailable",
	
	// Product Tab
	CONFIGURATION: "Configuration",
	
	// My Account Page Messages
	QC_UPDATE_SUCCESS : "Quick checkout profile updated successfully!",
	
	// This line defines the Quantity {0} and the component name {1} of a dynamic kit.  If a kit has a component with quantity 3, it will show as: 3 x Harddrive.
	// To show the string "Harddrive : 3", simply change this line to:  {1} : {0}.
	ITEM_COMPONENT_QUANTITY_NAME: "${0} x ${1}",
	
	//Sterling Order Line Status
	ORDER_LINE_STATUS_S : "Order Shipped",
	ORDER_LINE_STATUS_G : "Order Processing",
	ORDER_LINE_STATUS_K : "Return Associated",
	ORDER_LINE_STATUS_V : "Partially Shipped",
	ORDER_LINE_STATUS_X : "Order Cancelled",
	
	//Shipping Description Methods
	SHIPPING_METHOD_UPS : "UPS",
	SHIPPING_METHOD_CONWAY : "CONWAY",
	
	//ECOMM-4745
	IMAGE_REFERENCE_MEDIA: "//media.tractorsupply.com/is/image/TractorSupplyCompany/${0}?$prod_det$",
		
	//Facet Error Message
	ERROR_FACET_PRICE_INVALID : "The price entered is not in a valid format.",
	QI_WAS : "was",
	QI_SAVE : "save",
	TL_OUT_OF_STOCK : "Out of Stock Alert",
   CONTACT_US : "Thank you for your email. A team member from the Customer Solutions Center will be reviewing your information and will respond to your email within one business day of receipt. Thank you.",
   TSC_PDP_INPUT_MAX_BOPIS_QTY : "We have only ${0} available for pick up. Please update your quantity or check other stores.",
   TSC_PDP_INPUT_MAX_ONLINE_QTY : "We have only ${0} available. Please update your quantity to  proceed with your purchase.",
 //cookie parameters
   LPSTORENUM : "lpStoreNum",
   LPZIPCODENUM : "lpZipCodeNum",
   LPISBOPIS : "lpIsBOPIS",
   LPCITY : "lpCity",
   LPSTATENAME : "lpStateName",
   PDP_UOM : "C62",
   LPISSTOREAISLEFLAGENABLED : "lpIsStoreAisleFlagEnabled",
   //BOPIS-996
	ERROR_SELECT_ADDRESS : "Please select an address",
	ERROR_SELECT_SAVED_ADDRESS : "Please select a saved address",
	ERROR_PROD_NOT_ORDERABLE : "The product ${0} cannot be ordered.",
	ITEMS_QTY_AVAILABLE_OVERLAY_SHP : "Items Available for Shipping",
	ITEMS_QTY_AVAILABLE_OVERLAY_PICK : "Items Available for Pickup",
	
	//Localized Store
    LOCALIZED_STORE:"Localized Store is",
    
    //BOPIS Split error message
    TSC_PDP_BOPIS_SPLIT_QTY : '<span class="font-bold">Pickup ${0} In Store today </span><span class="free">(free)</span> at this store, and <span class="font-bold">Ship ${1} to this store</span> for pickup on a later date.',
    TSC_PDP_SPLIT_S2S : '<span class="font-bold">Ship all ${0} to this store</span> for pickup on a later date.',
  //QuickInfo Redesign Changes
    SELECT_COLOR_SIZE : "Please Select color and / or size",
    QUANTITY_ERROR : "Please enter quantity of one or more.",
    
    //OMS Upgrade changes
    BY_FALLBACK:"RTAM",
    PDP_QTYUPDATE:"qtyUpdate",
    PDP_ONLOAD : "pdpPageLoad",
    DELIVERY_MODAL:"deliveryModal"

}) 
