Widget={
		ContentRecommendationRefresh: function(emsName,triggerPublish){
			var contextId  = 'ContentRecommendationESpot_Widget_'+emsName;
			var containerId = contextId;
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, [], {});
			}
			var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
			wcRenderContext.addRefreshAreaId(contextId, containerId);
			myWidgetObj.refreshWidget({
				renderContextChangedHandler: function() {
					myWidgetObj.refreshWidget("refresh", myRCProperties);
				},
				postRefreshHandler: function(args) {
					cursor_clear();
					triggerPublish = wcRenderContext.getRenderContextProperties(contextId).triggerPublish;
					if(!!triggerPublish && triggerPublish){
						TSCUtils.publish(emsName+"_postAction");
					}else{
						TSCUtils.publish("IMAGE_LAZYLOAD_EVENT");
					}
				}
			});
		},
		bindEmailWidget: function(){
			$('.emailsignuparrow').off('click').on('click',function(event){
    			event.preventDefault();
    			currentId = $(this).parents('form').attr('id');
    			Widget.processEmailSignUp(currentId);
    		})
    		$("#emailSignUpForm #email , #emailSignUpFormFooter #emailFooter").unbind("keypress").keypress(function(event){
				if(event.which == 13){
					event.preventDefault();
					currentId = $(this).parents('form').attr('id');
					Widget.processEmailSignUp(currentId);
				}
			});
		},
		processEmailSignUp: function(currentId){
			var emailId = $('#'+currentId+' input#email').val();
			if(currentId === 'emailSignUpFormFooter'){
				emailId = $('#'+currentId+' input#emailFooter').val();
			}
			if(emailId === "") {
				$('#'+currentId+' #errmsg').html("Please enter an email address.");
				return;
			}else if(!TSCUtils.isValidEmail(emailId)){
				$('#'+currentId+' #errmsg').html("Please provide valid email address.");
				return;
			}
			$('#'+currentId+' #errmsg').html("");
			var service = wcService.getServiceById('AjaxTSCEmailSignUp');
			service.setFormId(currentId);
			service.setActionId(currentId);
			wcService.invoke("AjaxTSCEmailSignUp");
		},
		

	     doCaptchaSecureAjaxCall:function(ajaxCall,params,action){
	             grecaptcha.enterprise.ready(function() {
	                  grecaptcha.enterprise.execute(document.getElementById('gcSiteKey').value,{action: action}).then(function(token) {
	                      params["g-recaptcha-response"] = token;
	                      wcService.invoke(ajaxCall, params);
	                    });
	                });
	            },
	     
	          //Footer Email Sign up changes.
	            emailSignUpFooterAndSubmit:function(){
	            	var emailFormat = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,8}(?:\.[a-z]{2})?)$/;
	            	var regName = /^[A-Za-z\s\-]+$/;
					
					$("#firstname").off("keyup").on("keyup", function () {
						$("#fname-error").hide(200);
						return;
					});

					$("#lastname").off("keyup").on("keyup", function () {
						$("#lname-error").hide(200);
						return;
					});

					$("#emailaddress").off("keyup").on("keyup", function () {
						$("#email-error").hide(200);
						return;
					});
	            	    
	            	if ($("#firstname").val().trim() == "" || !regName.test($("#firstname").val())) {
	          	      $("#fname-error").show(200);
	          	      return false;
	          	     
	          	    }  else if ($("#lastname").val().trim() == "" || !regName.test($("#lastname").val())) {
	          	      $("#lname-error").show(200);
	          	      return false;
	          	     
	          	    } else if ($("#emailaddress").val().trim() == "" || !emailFormat.test($("#emailaddress").val())) {
	          	      $("#email-error").show(200);
	          	      return false;	          	     
	          	    } else {
						$("#fname-error").hide(200);
						$("#lname-error").hide(200);
						$("#email-error").hide(200);
					}                               
                var params = [];
                params['email'] = $("#emailaddress").val().trim();
                params['firstName'] = $("#firstname").val().trim();
                params['lastName'] = $("#lastname").val().trim();
                
                if(isSecureCaptchaEnabled("EnableFooterEmailRecaptcha")){ 
                    Widget.doCaptchaSecureAjaxCall("AjaxTSCRestFooterEmailSignUp",params,"Register");
                    }else{
                    wcService.invoke("AjaxTSCRestFooterEmailSignUp", params);
                }
         }
};	

	